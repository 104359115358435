import Google from './google';
import FbPixel from './facebook';
import Hubspot from './hubspot';
import UET from './microsoftAds';

export const initTrackers = () => {
  // Google.init();
  Google.initGoogleTagManager();
  Hubspot.init();
  FbPixel.init();
  UET.init();
  return;
};

export const trigPageview = location => {
  Google.pageview();
  Hubspot.pageview(location);
  FbPixel.pageview();
  UET.pageview(location);
  return;
};