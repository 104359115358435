// import Debounce from './debounce';
// import { loadDynamicScript } from './loadDynamicAssets';
import { hasLoggedIn } from './hasLoggedIn';

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days*24*60*60*1000));
  const expires = '; expires=' + date.toGMTString();
  document.cookie = name + '=' + value + expires + ';path=/;domain=paperlesspipeline.com';
}

const getParam = p => {
  const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

/*
const debounceSendGtag = Debounce((event, event_category, event_label, cb = null, event_params = null) => {
  if (IS_DEV) {
    event = `TESTING_${event}`;
    event_label = `TESTING_${event_label}`;
    event_category = `TESTING_${event_category}`;
    // return console.info('send google event testing: \n' + event + '\n' + event_label + '\n' + event_category);
    return;
  }
  if (sentEventArr.includes(event)) {
    return;
  }
  sentEventArr.push(event);
  if (event_params) {
    return window.gtag('event', event, event_params);
  }
  window.gtag('event', event, {
    'event_category': event_category && event_category.length ? event_category : 'no_category',
    'event_label': event_label && event_label.length ? event_label : 'no_label',
    'event_callback': () => {
      if (cb) {
        cb();
      }
    }
  });
}, 500);
*/

const Google = {
  ts: null,
  didInit: false,
  storeGoogleGclidInCookie: function() {
    if (!this.didInit) {
      return;
    }
    // code taken from: https://support.google.com/google-ads/answer/7012522
    const gclid = getParam('gclid');
    if (gclid) {
      const gclsrc = getParam('gclsrc'); // gclsrc is added by Search Ads 360 - do not want
      if (!gclsrc || gclsrc.indexOf('aw') !== -1) {
        setCookie('gclid', gclid, 90);
      }
    }
  },
  setTimestamp: function(ts) {
    this.ts = ts;
  },
  trackEventGTM: function(event, event_label, event_category) {
    if (
      typeof window === 'undefined' || // need check for gatsby build
      hasLoggedIn() ||
      ( this && !this.didInit )
    ) {
      return;
    }
    // console.log(event + ', ' + event_label + ', ' + event_category);
    window.dataLayer?.push({
      'event': event,
      'event_category': event_category,
      'event_label': event_label
     });
  },
  /*
  sendGtagEvent: function(event, event_label, event_category, cb = null, event_params = null) {
    if (
      hasLoggedIn() ||
      typeof window === 'undefined' || // need check for gatsby build
      !window.gtag
    ) {
      return;
    }
    if (this && this.ts && this.ts.length) {
      const noSlashes = this.ts.replace(/\//g, '');
      event_category = `${event_category}_${noSlashes}`;
    }
    debounceSendGtag(event, event_category, event_label, cb, event_params);
  },
  */
  pageview: function() {
    /*
    *
    *
    * check out this method too, using the history event:
    * https://www.analyticsmania.com/post/single-page-web-app-with-google-tag-manager/#method2
    *
    *
    */
    if (
      typeof window === 'undefined' || // need check for gatsby build
      hasLoggedIn() ||
      ( this && !this.didInit )
    ) {
      // console.info('NO google pageview tracking');
      // console.info('hasLoggedIn: ' + hasLoggedIn() + ', window: ' + (typeof window) + ', this.didInit: ' + this.didInit);
      return;
    }
    const { pathname, href, hash } = window.location;
    window.dataLayer?.push({
      'event': 'page_view',
      'pathname': pathname,
      'href': href,
      'hash': hash
     });
  },
  initGoogleTagManager: function() {
    if (
      typeof window === 'undefined' || // need check for gatsby build
      hasLoggedIn() ||
      ( this && this.didInit )
    ) {
      console.info('NO GoogleTagManager');
      return;
    }

    this.didInit = true;
    // https://developers.google.com/tag-platform/tag-manager/datalayer
    window.dataLayer = window.dataLayer || [];

    // need to switch to PROD_ID, here and in blog code!!!!!!
    const GTM_PROD_ID = 'GTM-52ZWCGS7';
    const GTM_MARKETING_DEV_ID = 'GTM-KK2JSRHL';

    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push(
        {
          'gtm.start': new Date().getTime(),event:'gtm.js'
        }
      );
      var f = d.getElementsByTagName(s)[0];
      var j = d.createElement(s)
      var dl = l != 'dataLayer' ? '&l='+l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j,f);
    })(window, document, 'script', 'dataLayer', GTM_MARKETING_DEV_ID);
    // capture initial page load
    this.pageview();
  },
  /*
  init: function() {
    this.didInit = true;
    loadDynamicScript('id_google', `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ADWORDS_ID}`, () => {
      window.dataLayer = window.dataLayer || [];
      var gtag = function(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', GOOGLE_ADWORDS_ID, { 'debug_mode': true });
      // gtag('config', GOOGLE_ANALYTICS_4_ID, { 'debug_mode': true });
      window.gtag = gtag;
      this.storeGoogleGclidInCookie(); // can only do this if GDPR consent is given...
    });
  }
  */
};

export default Google;